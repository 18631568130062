<template>
    <div class="modal">
      <div class="modal-content" :class="bgColorClass" v-if="content">
        <span class="close" @click="$emit('close')">&times;</span>

        <div>
            <br/>
            <h3>{{ attended }}</h3>
            <br/>
            <h1>{{ documentData.name }}</h1>
            <h2>({{ documentData.relation }})</h2>
    
 
        </div>
        
        <!-- Go to Home Button -->
        <div class="tutup" @click="goHome">TUTUP</div>
      </div>
    </div>
  </template>
  
  
  <script>
import { ref, onMounted, computed } from 'vue';
import { db } from '@/firebaseConfig';
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';

export default {
  props: ['content'], // Accepting the 'content' prop

  setup(props, { emit }) {
    const documentData = ref({}); // Initialize reactive document data


    const id = computed(() => {
    try {
        // Attempt to split the content and return the last part
        const parts = props.content.split('-');
        return parts.length > 0 ? parts[parts.length - 1] : '';
    } catch (error) {
        // Handle the error gracefully
        console.error('An error occurred while computing the id:', error);
        // Return a default value or handle the error as needed
        return '';
    }
    });

    // change bg color depend on vip

    const bgColorClass = computed(() => {
        if (documentData.value.attend == true) {
            return 'warning-background';
        } else {
            return documentData.value.vip ? 'vip-background' : 'non-vip-background';
        }
    });


    const attended = computed(() => {
        return documentData.value.attend ? 'ANDA TELAH MENGISI BUKU TAMU SEBELUMNYA!!' : 'TERIMA KASIH TELAH BERKENAN HADIR';
    });


    // Lifecycle hook to fetch document data on component mount
    onMounted(async () => {
      
        if (id.value) { // Ensure 'id' is not empty
            const docRef = doc(db, "undangan", id.value);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                documentData.value = docSnap.data();
                // Optionally, update the 'attend' field to true
                if(documentData.value.attend == false){
                    await updateDoc(docRef, {
                        attend: true,
                        attend_time: serverTimestamp()
                    });
                } else {
                    console.log("No such document!");
                }
            }
        }
        
    });

    // Method to emit 'goHome' event
    const goHome = () => {
      emit('goHome');
    };

    // Expose reactive data and methods
    return {
      documentData,
      bgColorClass,
      attended,
      goHome
    };
  },
};
</script>

  
  
  <style scoped>

    .vip-background {
    background-color: gold !important; /* Or any shade of green you prefer */
    }

    .non-vip-background {
    background-color: white;
    }

    .warning-background {
    background-color: red !important;
    }

  .tutup{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
   
    background-color: coral;
    position: absolute;
   
    height: 90px;
    width: 50%;
    bottom: 10%;
    
    left: 50%;
    transform: translateX(-50%);

    font-size: 20px;
  }
  .modal {
    display: block; /* Ensure modal is shown when this component is rendered */
    position: fixed;
    z-index: 1; /* Adjust if necessary to ensure it's above other content */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    height: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  
  @keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  </style>
  