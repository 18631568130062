<template>
    <h1>BUKU TAMU SETRUM - SYARAH & FARID</h1>
    <nav>
      <router-link to="/">HOME</router-link> |
      <router-link to="/pindai">PINDAI KODE QR</router-link> | 
      <router-link to="/manual">KETIK MANUAL</router-link>
    </nav>
    <router-view/>


</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav {
    padding: 0px 0px 60px 0px;
  }
  
  nav a {
    font-weight: bold;
    color: #2c3e50;
  }
  
  nav a.router-link-exact-active {
    color: #42b983;
  }
</style>
